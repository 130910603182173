<template v-if="loged">
    <mnSup pauta="true" mostrar="true" @buscar="buscarProyecto"/>


    <div class="wrap error" v-if="!prResultado && prResultado!=null">
        <h2>Lo sentimos el proyecto no existe</h2>
    </div>
    <div class="wrap error" v-if="prData==''">
        <h2>Seleccione un proyecto para empezar <i class="fas fa-arrow-up"></i></h2>
    </div>
    
    <main id="pauta" v-if="prData!='' && prData!=undefined && prResultado">
        <h1>Pauta de Evaluación</h1>
        <section class="wrap infoExtra">
            <h2>Descargables</h2>
            <div class="descargables">
                <a target="_blank" href="/assets/pdf/Guia_de_Evaluacion_XXI_Congreso_Nacional.pdf"><i class="fas fa-file-download"></i> Guía de Evaluación</a>
                <a target="_blank" href="/assets/pdf/REx165BasesCongresos.pdf"><i class="fas fa-file-download"></i> REx 165 Bases de Congresos</a>
            </div>

            
            <div class="firma">
                <h2>Adjunte su firma</h2>
                <p>Debe ser imágen <b>jpg o png</b> y no pesar más de <b>2mb</b></p>
                <form @submit.prevent="formFirma">
                <div class="boxItem">
                    <div class="formItem">
                        <input type="file" ref="fileInput" accept="image/*" name="firma" @change="firmaChange($event.target)" />
                    </div>
                    <button type="button" class="btnClearFirma" v-if="firmaBtn" @click="clearFirma()"><i class="fas fa-times"></i></button>
                </div>
                <div class="msg">{{imgSubido}}</div>
                <div class="boxSubmit">
                        <button type="submit" class="btn" :disabled="!firmaBtn">SUBIR FIRMA</button>
                </div>
                </form>

                <p>Importante: Toda la información relacionada con la evaluación es confidencial. No se debe compartir ni guardar registros. Le sugerimos realizar un "Print de pantalla" como respaldo.</p>
            </div>
        </section>


        <section class="wrap prInfo">
            <ul class="grid-col2 headerEval">
                <li>
                    <span>Nombre Evaluador/a</span>
                    <span>{{this.session.profile.nombre}}</span>
                </li>
                <li>
                    <span>Fecha Evaluación</span>
                    <span>{{this.fHoy}}</span>
                </li>
            </ul>
            <div class="grid-col2">
                <ul>
                    <li>
                        <span>Código de Proyecto</span>
                        <span>{{prData.codigo}}</span>
                    </li>
                    <li>
                        <span>Nombre Proyecto</span>
                        <span>{{prData.titulo}}</span>
                    </li>
                    <li>
                        <span>Categoría</span>
                        <span>{{prData.categoria}}</span>
                    </li>
                    <li>
                        <span>OCDE</span>
                        <span>{{prData.disciplina_OCDE}}</span>
                    </li>
                </ul>
                <ul>
                    <li>
                        <span>Región</span>
                        <span>{{prData.PAR}}</span>
                    </li>
                    <li>
                        <span>Comuna</span>
                        <span>{{prData.comuna}}</span>
                    </li>
                    
                    <li>
                        <span>Establecimiento</span>
                        <span>{{prData.establecimiento}}</span>
                    </li>
                    <li>
                        <span>Nivel</span>
                        <span>{{prData.nivel}}</span>
                    </li>
                    <li>
                        <span>Docente</span>
                        <span>{{prData.docente_guia_nombre}} {{prData.docente_guia_apellido}}</span>
                    </li>
                </ul>
            </div>
        </section>

        


        <section class="wrap pautaVideo evalSection">
            <h2>Etapa 1 de evaluación: Video de presentación (50%)<br><span>Proceso de evaluación de videos es del 16 al 20 de enero 2023</span></h2>
            <p class="especial"><a :href="prData.links.video.evaluacion" target="_blank">Ver video:<br>{{prData.links.video.evaluacion}}</a></p>

            <div class="infExtra">
                <h3>Indicaciones de Evaluación</h3>
                <p>Para evaluar por favor tener en cuenta lo siguiente:</p>
                <ul>
                    <li>Leer las Bases del XXI CONGRESO NACIONAL EXPLORA DE INVESTIGACIÓN E INNOVACIÓN ESCOLAR del año 2022</li>
                    <li>Participar de la/s jornada/s de inducción para evaluadoras y evaluadores organizada por el programa Explora.</li>
                    <li>Leer atenta y rigurosamente el informe escrito de la investigación o proyecto de innovación escolar como insumo para la evaluación y entender el contexto del video.</li>
                    <li>Observar el video de presentación que será evaluado respecto al contenido.</li>
                    <li>Leer y analizar cada uno de los criterios y subcriterios de la pauta de evaluación.</li>
                    <li>Conocer la escala y aplicarla en números enteros (no decimales).</li>
                    <li>Registrar sus comentarios justificando los puntajes asignados a la investigación o proyecto de innovación en relación con los criterios evaluados. Consigne además sugerencias, críticas constructivas y comentarios positivos/destacables al equipo realizador que aporten a mejorar este o futuros trabajos considerando el nivel educativo de las y los estudiantes.</li>
                </ul>
                <table>
                    <tr>
                        <th>Concepto</th>
                        <th>Pje</th>
                        <th>Descripción</th>
                    </tr>
                    <tr>
                        <td>No califica</td>
                        <td>0</td>
                        <td>La investigación o proyecto de innovación no cumple o no aborda el criterio analizado o no puede ser evaluado debido a la falta de antecedentes o información incompleta.</td>
                    </tr>
                    <tr>
                        <td>Deficiente</td>
                        <td>1</td>
                        <td>La investigación o proyecto de innovación no cumple o no aborda adecuadamente los aspectos del criterio o hay graves deficiencias inherentes.</td>
                    </tr>
                    <tr>
                        <td>Regular</td>
                        <td>2</td>
                        <td>La investigación o proyecto de innovación cumple o aborda en términos generales los aspectos del criterio, pero existen importantes deficiencias.</td>
                    </tr>
                    <tr>
                        <td>Bueno</td>
                        <td>3</td>
                        <td>La investigación o proyecto de innovación cumple o aborda correctamente los aspectos del criterio, pero requiere de mejoras.</td>
                    </tr>
                    <tr>
                        <td>Muy bueno</td>
                        <td>4</td>
                        <td>La investigación o proyecto de innovación cumple o aborda los criterios de muy buena manera, pero requiere de ciertas mejoras.</td>
                    </tr>
                    <tr>
                        <td>Excelente</td>
                        <td>5</td>
                        <td>La investigación o proyecto de innovación cumple o aborda de manera sobresaliente todos los aspectos relevantes del criterio en cuestión, y no requiere de ninguna mejora en relación a lo presentado.</td>
                    </tr>
                </table>
            </div>
            <div class="boxEvalData" v-if="evalData!=''">
                <form @submit.prevent="">
                    <div class="criterio">
                        <template v-for="(pregunta, index) in evalData.preguntas[0]" :key="'eval'+index">
                            <h4>{{pregunta.criterio}}</h4>
                            <ul class="rows">
                                <li class="row" v-for="(subcriterio, index) in pregunta.subcriterios" :key="'sc'+index">
                                    <span class="col">{{subcriterio.subcriterio}}</span>
                                    <span class="col">
                                        <div class="boxItem">
                                            <div class="formItem" v-if="subcriterio.estado == 3">
                                                Cerrado
                                            </div>
                                            <div class="formItem inputSelect" v-else>
                                                <select :name="`Sel-${pregunta.id}-${subcriterio.id}`" @change="this.sumarPromedios(0, pregunta.id, subcriterio.id)" class="sv" :id="subcriterio.id">
                                                    <option :value="opcion.puntaje" v-for="(opcion, index) in subcriterio.opciones" :key="'opt'+index" :selected="opcion.puntaje == subcriterio.respuesta">({{opcion.puntaje}}) {{opcion.concepto}}</option>
                                                </select>
                                                <i class="fas fa-chevron-down"></i>
                                            </div>
                                            
                                        </div>
                                    </span>
                                </li>
                            </ul>
                            <div class="resultadoParcial">
                                <p>Promedio esta sección: <b :id="`promedio0-${pregunta.id}`"> {{promedios.subTotales1[index]}}</b></p>
                            </div>
                        </template>
                    </div>
                    <div class="promedioTotal">
                        <p>Promedio Total Primera Parte: <b>{{promedios.total1}}</b></p>
                    </div>
                    <div class="infoObserva">
                        <p>Estimado/a Evaluador/a:</p>
                        <p>A continuación, registre sus comentarios justificando los puntajes asignados a la investigación o proyecto de innovación en relación con los criterios evaluados.</p>
                        <p>Consigne además comentarios positivos y/o destacables, sugerencias o críticas constructivas al equipo realizador que aporten a mejorar este o futuros trabajos considerando el nivel educativo de las y los estudiantes.</p>
                    </div>
                    <div class="observaciones">
                        <textarea name="" id="" cols="30" rows="10" placeholder="Escriba aquí sus observaciones" v-model="form.observaciones1">-</textarea>
                    </div>
                    <div class="comentariosStaff" v-if="comentarioStaff1!=''">
                        <h2>Comentarios del Staff:</h2>
                        <ul class="wrap">
                            <li v-for="(comentario, index) of comentarioStaff1" :key="'com1'+index">
                                <div class="by"><span>{{comentario.created_at}}:</span> {{comentario.created_by}}</div>
                                <div class="comentarios">{{comentario.comentario}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="nota">
                        <p>Nota: Una vez finalizada su evaluación solamente podrá realizar cambios en la sección de comentarios, las notas <b>NO</b> podrán editarse</p>
                    </div>
                    <div class="boxSubmit">
                        <button class="btn" @click="guardarEva('sv')">Guardar</button>
                        <button class="btn finalizar" @click="finalizarEva(1)" >Finalizar</button>
                    </div>
                </form>
            </div>
        </section>

        <section class="wrap pautaEva evalSection">
            <h2>Etapa 2 de evaluación: Preguntas y respuestas (50%)<br><span>Proceso de evaluación preguntas y respuestas es del 23 al 27 de enero 2023</span></h2>
            <div class="infExtra">
                <h3>Indicaciones de Evaluación</h3>
                <p>Para evaluar por favor tener en cuenta lo siguiente:</p>
                <ul>
                    <li>Leer las Bases del XXI CONGRESO NACIONAL EXPLORA DE INVESTIGACIÓN E INNOVACIÓN ESCOLAR del año 2022</li>
                    <li>Participar de la/s jornada/s de inducción para evaluadoras y evaluadores organizada por el programa Explora.</li>
                    <li>Leer atenta y rigurosamente el informe escrito de la investigación o proyecto de innovación escolar como insumo para la evaluación y entender el contexto del video.</li>
                    <li>Observar el video de presentación que será evaluado respecto al contenido.</li>
                    <li>Preparar al menos dos preguntas que puedan permitir profundizar en la investigación o proyecto de innovación.</li>
                    <li>Leer y analizar cada uno de los criterios y subcriterios de la pauta de evaluación.</li>
                    <li>Conocer la escala y aplicarla en números enteros (no decimales).</li>
                    <li>Registrar sus comentarios justificando los puntajes asignados a la investigación o proyecto de innovación en relación con los criterios evaluados. Consigne además sugerencias, críticas constructivas y comentarios positivos/destacables al equipo realizador que aporten a mejorar este o futuros trabajos considerando el nivel educativo de las y los estudiantes.</li>
                </ul>
                <table>
                    <tr>
                        <th>Concepto</th>
                        <th>Pje</th>
                        <th>Descripción</th>
                    </tr>
                    <tr>
                        <td>No califica</td>
                        <td>0</td>
                        <td>La investigación o proyecto de innovación no cumple o no aborda el criterio analizado o no puede ser evaluado debido a la falta de antecedentes o información incompleta.</td>
                    </tr>
                    <tr>
                        <td>Deficiente</td>
                        <td>1</td>
                        <td>La investigación o proyecto de innovación no cumple o no aborda adecuadamente los aspectos del criterio o hay graves deficiencias inherentes.</td>
                    </tr>
                    <tr>
                        <td>Regular</td>
                        <td>2</td>
                        <td>La investigación o proyecto de innovación cumple o aborda en términos generales los aspectos del criterio, pero existen importantes deficiencias.</td>
                    </tr>
                    <tr>
                        <td>Bueno</td>
                        <td>3</td>
                        <td>La investigación o proyecto de innovación cumple o aborda correctamente los aspectos del criterio, pero requiere de mejoras.</td>
                    </tr>
                    <tr>
                        <td>Muy bueno</td>
                        <td>4</td>
                        <td>La investigación o proyecto de innovación cumple o aborda los criterios de muy buena manera, pero requiere de ciertas mejoras.</td>
                    </tr>
                    <tr>
                        <td>Excelente</td>
                        <td>5</td>
                        <td>La investigación o proyecto de innovación cumple o aborda de manera sobresaliente todos los aspectos relevantes del criterio en cuestión, y no requiere de ninguna mejora en relación a lo presentado.</td>
                    </tr>
                </table>
            </div>
            <div class="boxEvalData" v-if="evalData!=''">
                <form @submit.prevent="">
                    <div class="criterio">
                        <template v-for="(pregunta, index) of evalData.preguntas[1]" :key="'eval2'+index">
                            <h4>{{pregunta.criterio}}</h4>
                            <ul class="rows">
                                <li class="row" v-for="(subcriterio, index) of pregunta.subcriterios" :key="'sc2'+index">
                                    <span class="col">{{subcriterio.subcriterio}}</span>
                                    <span class="col">
                                        <div class="boxItem">
                                            <div class="formItem" v-if="subcriterio.estado == 3">
                                                Cerrado
                                            </div>
                                            <div class="formItem inputSelect" v-else>
                                                <select :name="`Sel-${pregunta.id}-${subcriterio.id}`" @change="this.sumarPromedios(1, pregunta.id, subcriterio.id)" class="sp" :id="subcriterio.id">
                                                    <option :value="opcion.puntaje" v-for="(opcion, i) of subcriterio.opciones" :key="'opt2'+i" :selected="opcion.puntaje == subcriterio.respuesta">({{opcion.puntaje}}) {{opcion.concepto}}</option>
                                                </select>
                                                <i class="fas fa-chevron-down"></i>
                                            </div>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                            <div class="resultadoParcial">
                                <p>Promedio esta sección: <b :id="`promedio1-${pregunta.id}`">{{promedios.subTotales2[index]}}</b></p>
                            </div>
                        </template>
                    </div>
                    <div class="promedioTotal">
                        <p>Promedio Total Segunda Parte: <b>{{promedios.total2}}</b></p>
                    </div>
                    <div class="infoObserva">
                        <p>Estimado/a Evaluador/a:</p>
                        <p>A continuación, registre sus comentarios justificando los puntajes asignados a la investigación o proyecto de innovación en relación con los criterios evaluados.</p>
                        <p>Consigne además comentarios positivos y/o destacables, sugerencias o críticas constructivas al equipo realizador que aporten a mejorar este o futuros trabajos considerando el nivel educativo de las y los estudiantes.</p>
                    </div>
                    <div class="observaciones">
                        <textarea name="" id="" cols="30" rows="10" placeholder="Escriba aquí sus observaciones" v-model="form.observaciones2"></textarea>
                    </div>
                    <div class="comentariosStaff" v-if="comentarioStaff2!=''">
                        <h2>Comentarios del Staff:</h2>
                        <ul class="wrap">
                            <li v-for="(comentario, index) of comentarioStaff2" :key="'com2'+index">
                                <div class="by"><span>{{comentario.created_at}}:</span> {{comentario.created_by}}</div>
                                <div class="comentarios">{{comentario.comentario}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="nota">
                        <p>Nota: Una vez finalizada su evaluación solamente podrá realizar cambios en la sección de comentarios, las notas <b>NO</b> podrán editarse</p>
                    </div>
                    <div class="boxSubmit">
                        <button class="btn" @click="guardarEva('sp')">Guardar</button>
                        <button class="btn finalizar" @click="finalizarEva(2)">Finalizar</button>
                    </div>
                </form>
            </div>
        </section>
        <section class="firma">
            <div class="wrap">
                <h2>Su firma:</h2>
                <div class="">
                    <img :src="firma" alt="">
                </div>
            </div>
            
        </section>
    </main>
    <article id="notaFinal" v-if="prData!='' && prData!=undefined && prResultado">
        <div class="wrap">
            <h5>Nota Final Evaluación:</h5>
            <h2>{{promedios.totalFinal}}</h2>
        </div>
    </article>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import mnSup from '@/components/mnSup.vue'

export default {
    name:'pauta-evaluaciones',
    data(){
        return{
            prCod:'',
            prData:'',
            prResultado:true,
            evalData:'',
            image: null,
            imgBase:'',
            imgSubido:'',
            firma:'',
            firmaBtn:false,
            comentarioStaff1:'',
            comentarioStaff2:'',
            form:{
                buscarPr:'',
                select:[],
                observaciones1:'',
                observaciones2:''
            },
            promedios:{
                subTotales1:[],
                subTotales2:[],
                total1:0,
                total2:0,
                totalFinal:0
            }
        }
    },
    components:{
        mnSup
    },
    async created(){
        document.title = 'PAUTA DE EVALUACIONES - XX Congreso Nacional Explora'
        await this.logVerify()
        this.fechaHoy()
    },
    mounted(){
        //this.totalEtapa()
    },
    methods:{
        ...mapMutations(['logVerify','fechaHoy', 'logOut']),
        async buscarProyecto(cod){
            this.form.buscarPr = cod;
            if(this.form.buscarPr!=''){
                let url = `${this.apiURL}/api/?clase=MasterData&metodo=getProyectFiltered&parametros={"filters":[{"field":"codigo", "value":"${this.form.buscarPr}"}]}`;
                try{
                    let rs = await fetch(url);
                    let datosRaw = await rs.arrayBuffer();
                    let decoder = new TextDecoder("ISO-8859-1");
                    let datosDec = decoder.decode(datosRaw);
                    let data = JSON.parse(datosDec);
                    this.prResultado = data.resultado;

                    if(data.resultado){
                        this.prData = data.salida[0];
                    }else{
                        console.error(data.error)
                        return
                    }

                }catch(e){
                    console.log(e);
                }
                this.loadEval()
            }
        },
        async loadEval(){
            if(this.prData.codigo != '' && this.prData.codigo != undefined){
                let url = `${this.apiURL}/api/?clase=MasterData&metodo=getSavedEvaluation&parametros={"project":"${this.prData.codigo}","username":"${this.session.username}"}`;

                try{
                    let rs = await fetch(url);
                    let datosRaw = await rs.arrayBuffer();
                    let decoder = new TextDecoder("ISO-8859-1");
                    let datosDec = decoder.decode(datosRaw);
                    let data = JSON.parse(datosDec);

                    if(data.resultado){
                        this.evalData = data.salida;
                    }

                    this.loadComentarios(1);
                    this.loadComentarios(2);
                    this.loadComentarios2(1);
                    this.loadComentarios2(2);
                    this.promedio(0);
                    this.promedio(1);

                    this.loadFirma();

                }catch(e){
                    console.log(e);
                }
            }else{
                console.error('Datos de proyecto no cargados')
            }
                
        },
        async loadComentarios(etapa){
                let url = `${this.apiURL}/api/?clase=MasterData&metodo=getComments&parametros={"project":"${this.prData.codigo}","step":${etapa},"username":"${this.session.username}"}`;
                try{
                    let rs = await fetch(url);
                    let data = await rs.json();
                    /* let datosRaw = await rs.arrayBuffer();
                    let decoder = new TextDecoder("ISO-8859-1");
                    let datosDec = decoder.decode(datosRaw);
                    let data = JSON.parse(datosDec); */
                    

                    if(data.resultado){
                        let com2filtered = data.salida.filter((d)=>{
                            return d.created_by == this.session.username
                        })

                        let l = com2filtered.length

                        if(etapa == 1){
                            this.form.observaciones1 = com2filtered[l-1].comentario;
                        }
                        if(etapa == 2){
                            this.form.observaciones2 = com2filtered[l-1].comentario;
                        }
                    }else{
                        if(etapa == 1){
                            this.form.observaciones1 = '';
                        }
                        if(etapa == 2){
                            this.form.observaciones2 = '';
                        }
                        console.log(`comentarios${etapa} sin resultados`)
                    }

                    

                }catch(e){
                    console.log(e);
                }
        },
        async loadComentarios2(etapa){
            let url = `${this.apiURL}/api/?clase=MasterData&metodo=getComments&parametros={"project":"${this.prData.codigo}","step":${etapa},"username":"${this.session.username}"}`;
            try{
                let rs = await fetch(url);
                let data = await rs.json();
                /* let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec); */

                if(data.resultado){
                    let com2filtered = data.salida.filter((d)=>{
                        return d.created_by != this.session.username
                    })

                    if(etapa == 1){
                        this.comentarioStaff1 = com2filtered;
                    }
                    if(etapa == 2){
                        this.comentarioStaff2 = com2filtered;
                    }
                }else{
                    console.log(`comentarios2-${etapa} sin resultados`)
                }

                

            }catch(e){
                console.log(e);
            }
        },
        promedio(etapa){
            let prEtapa = this.evalData.preguntas[etapa];

            //PROMEDIO SUBCRITERIOS
            prEtapa.forEach((Cr, i)=>{
                let value = 0;
                Cr.subcriterios.forEach((scr)=>{
                    value += scr.respuesta == null ? 0 : parseInt(scr.respuesta);
                })
                let valor = value/Cr.subcriterios.length

                if(etapa==0){
                    this.promedios.subTotales1[i] = valor
                }
                if(etapa==1){
                    this.promedios.subTotales2[i] = valor
                }
            })

            //PROMEDIO CRITERIOS
            let value1 = 0;
            this.promedios.subTotales1.forEach((n)=>{
                value1+=n
            })
            let valor1 = value1/this.promedios.subTotales1.length
            this.promedios.total1 = valor1.toFixed(2)

            let value2 = 0;
            this.promedios.subTotales2.forEach((n)=>{
                value2+=n
            })
            let valor2 = value2/this.promedios.subTotales2.length
            this.promedios.total2 = valor2.toFixed(2)

            //PROMEDIO ETAPA
            let promedios = document.querySelectorAll(`b[id^="promedio${etapa}-"]`);
            let prvalor = 0;
            promedios.forEach((b) => {
                prvalor += parseFloat(b.innerHTML)
            })
            prvalor = prvalor/promedios.length


            //PROMEDIO FINAL EVALUACION
            let totalFinal = (parseFloat(this.promedios.total1) + parseFloat(this.promedios.total2))/2;
            this.promedios.totalFinal = parseFloat(totalFinal).toFixed(2);
        },
        sumarPromedios(etapa, cr, scr){ //onChange select

            //PROMEDIO CRITERIOS
            let selectCriterio = document.querySelectorAll(`select[name^="Sel-${cr}"]`);
            let valor = 0;
            arrayCr.forEach((select) => {
                valor += parseInt(select.value)
            })
            valor = valor/selectCriterio.length;

            //--mostrar resultado criterios
            let promedio = document.querySelector(`#promedio${etapa}-${cr}`);
            promedio.innerHTML = valor;
            
            //PROMEDIO ETAPA
            let promedios = document.querySelectorAll(`b[id^="promedio${etapa}-"]`);
            let prvalor = 0;
            promedios.forEach((b) => {
                prvalor += parseFloat(b.innerHTML)
            })
            prvalor = prvalor/promedios.length

            //--mostrar resultado etapa
            if(etapa === 0){
                this.promedios.total1 = prvalor.toFixed(2)
            }
            if(etapa === 1){
                this.promedios.total2 = prvalor.toFixed(2)
            }

            //PROMEDIO FINAL EVALUACION
            let totalFinal = (parseFloat(this.promedios.total1) + parseFloat(this.promedios.total2))/2;
            this.promedios.totalFinal = parseFloat(totalFinal).toFixed(2);

        },
        async guardarEva(etapa){
            let selects = document.querySelectorAll(`select.${etapa}`);
            let saved = true;

            //guardar respuestas
            for(let i = 0; i < selects.length; i++){
                console.log(`guardando.. ${selects[i].id}: ${selects[i].value}`);
                let respSaved = await this.guardarRespuestas(selects[i].id, selects[i].value, this.session.username)
                if(!respSaved){
                    saved = false;
                }
            };

            //guardar comentarios
            let etapaComent = etapa=='sv' ? 1 : 2;
            await this.guardarComentario(etapaComent);

            if(saved){
                alert('La evaluación ha sido guardada');
            }else{
                alert('Error al guardar la evaluación, intente nuevamente');
            }
        },
        async guardarRespuestas(scId, scR, user){
            let url = `${this.apiURL}/api/?clase=MasterData&metodo=setEvaluation&parametros={"proyecto":"${this.prData.codigo}","id_subcriterio":${scId},"respuesta":${scR},"username":"${user}"}`;

            try{
                let rs = await fetch(url);
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);

                if(data.resultado){
                    return data.salida
                }else{
                    console.error(data.error)
                    return data.salida
                }
            }catch(e){
                console.log(e);
            }
        },
        async guardarComentario(etapa){
            let observaciones = '-';
            if(etapa==1){
                observaciones = this.form.observaciones1;
            }
            if(etapa==2){
                observaciones = this.form.observaciones2;
            }

            observaciones = JSON.stringify(observaciones);

            let url = `${this.apiURL}/api/`
            try{
                let rs = await fetch(url,{
                     method: "POST", 
                     body: `clase=MasterData&metodo=setComment&parametros={"project":"${this.prData.codigo}","step":${etapa},"evaluator":"${this.session.username}","username":"${this.session.username}","comment":${observaciones}}`
                    });
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);

            }catch(e){
                console.log(e);
            }

        },
        async finalizarEva(etapa){
            console.log(`Finalizando Proyecto etapa: ${etapa}...`);
            if(confirm('Le recomendamos revisar con atención sus notas antes de poner finalizar, esta acción es irreversible. \n\n ¿Seguro desea continuar?')){
                
                let evaEtapa;
                if(etapa == 1){
                    evaEtapa = 'sv'
                }else if(etapa == 2){
                    evaEtapa = 'sp'
                }else{
                    console.error('Número de etapa incorrecto')
                    return
                }

                await this.guardarEva(evaEtapa)
                console.log('Evaluación guardada....')

                let url = `${this.apiURL}/api/?clase=MasterData&metodo=closeEvaluation&parametros={"project":"${this.prData.codigo}","step":${etapa},"username":"${this.session.username}"}`
                try{
                    let rs = await fetch(url);
                    let datosRaw = await rs.arrayBuffer();
                    let decoder = new TextDecoder("ISO-8859-1");
                    let datosDec = decoder.decode(datosRaw);
                    let data = JSON.parse(datosDec);

                    if(data.resultado){
                        window.location.href = '/pauta-evaluaciones'
                    }else{
                        console.error(data.error)
                    }
                }catch(e){
                    console.log(e);
                }

                alert('Su evaluación ha sido finalizada desde ahora no podra hacer cambios en las notas');
            }
        },
        async proyectoEvaluador(){
            let url = `${this.apiURL}/api/?clase=MasterData&metodo=getProjectsByEvaluator&parametros={"username":"${this.session.username}"}`
            try{
                let rs = await fetch(url);
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);

            }catch(e){
                console.log(e);
            }
        },
        firmaChange(el){
            if(el.value != ''){
                this.firmaBtn = true;
            }
        },
        clearFirma(){
            let firmaInput = document.querySelector('input[name="firma"]');
            firmaInput.value = '';
            this.firmaBtn = false;
        },
        archivoClick (event) {
            var MaxSize = 2048;//Tamaño máximo en kBytes


            const files = event.target.files
            let filename = files[0].name
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
                this.sendFile(this.imageUrl);
            })
            
            fileReader.readAsDataURL(files[0])
            this.image = files[0];

        },
        async sendFile(img64){
            let url = `${this.apiURL}/api/`
            try{
                let rs = await fetch(url,{
                    method: "POST",
                    body: `clase=MasterData&metodo=setSignature&parametros={"file":"${img64}","evaluator":"${this.session.username}"}`,
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded"
                    }
                });
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);

                if(data.resultado){
                    this.imgSubido = 'La firma ya fue subida, la verá al final de la evaluación.';
                    this.clearFirma();
                    this.loadFirma();
                }else{
                    console.error(data.error)
                }
            }catch(e){
                console.log(e);
            }
        },
        async loadFirma(){
            let url = `${this.apiURL}/api/?clase=MasterData&metodo=getSignature&parametros={"evaluator":"${this.session.username}"}`
            console.log('cargando firma...')

            try{
                let rs = await fetch(url);
                //let data = await rs.json();
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);

                if(data.resultado){
                    let rplc1 = data.salida;
                    let rplc2 = rplc1.replace(/\s/g,"+");
                    this.firma = rplc2;
                    console.log('Firma Cargada <<')
                }else{
                    console.error('Error en la API')
                }

            }catch(e){
                console.log(e);
            }

        },


        formFirma(form){
            console.log(form)
            this.subirArchivo(form.srcElement[0])
        },
        subirArchivo(obj){
            //Recibe como parámetro el Objeto FileUpload.


            let MaxSize = 2048;//Tamaño máximo en kBytes
            //Procesar imagen subida
            let img = new Image();


            console.log('Peso',(obj.files[0].size/1024).toFixed(2));

            if (!(/\.(jpg|jpeg|png)$/i).test(obj.files[0].name)){
                console.log('Error:El archivo no es una imagen válido.');
                this.imgSubido = 'Error: El archivo no es una imagen válido.'
                return false
            }else if((obj.files[0].size/1024) > MaxSize){
                console.log('Error: El archivo pesa más de '+MaxSize+'kB');
                this.imgSubido = 'Error: El archivo pesa más de 2Mb'
                return false
            }else{
                var reader = new FileReader();
                reader.addEventListener('load', () => {
                    this.imageUrl = reader.result
                    this.sendFile(this.imageUrl);
                })
                reader.readAsDataURL(obj.files[0]);
            }



        },
        
    },
    computed:{
        ...mapState(['apiURL', 'loged', 'session', 'fHoy']),
    }
}
</script>





<style lang="scss" scoped>
.error{
    padding:40px;
    h2{
        text-align: center;
    }
}
header{
    background-color: #000;
    color:#fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px;
    .logo{
        width: 300px;
        img{
            width: 100%;
            height: auto;
        }
    }
    div.boxFind{
        display: flex;
        gap:40px;
        align-items: center;
        form.buscarPr{
            padding:0px;
            .boxItem{
                display: flex;
                gap:10px;
                align-items: center;
                margin-bottom:0px;
                label{
                    font-size: 1.1rem;
                    position: relative;
                    i{
                        font-size: 1.3em;
                        position: relative;
                        top:2px;
                        margin:0px 10px;
                    }
                }
                .formItem{
                    position: relative;
                    overflow: hidden;
                    input{
                        border-radius: 50px;
                    }
                    button{
                        background: transparent;
                        cursor: pointer;
                        position:absolute;
                        top:10px;
                        right:10px;
                        color: var(color2) !important;;
                    }
                }
            }
            
        }
        .userBox{
            display: flex;
            gap: 10px;
            align-items: center;
            padding-left:30px;
            border-left:1px solid #fff;
            .userIco{
                border:1px solid #fff;
                padding:5px;
                border-radius: 50px;
                width: 35px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                    position: relative;
                    top:-1px;
                }
            }
            button.btn{
                background-color: #000 !important;
                border:1px solid #fff !important;
            }
        }
    }
    nav{
        
        ul{
            display: flex;
            gap:10px;
            li{
                list-style: none;
                a{
                    color:#fff;
                    text-decoration: none;
                    padding:5px 8px;
                    &:hover{
                        border-bottom:3px solid var(--color2);
                        text-decoration: none;
                    }
                    &.navActive{
                        border-bottom:3px solid var(--color2);
                        text-decoration: none;
                    }
                    &.router-link-exact-active{
                        border-bottom:3px solid var(--color2);
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
main#pauta{
    background-image: url('../../public/assets/img/buscador-fondo.svg');
    min-height: calc(100vh - 133px);
    padding:50px 20px;
    h1{
        text-align: center;
        padding:20px 30px;
        margin: auto;
        margin-bottom: 30px;
        width: fit-content;
        background-color: var(--color2);
        color:#fff;
        position: relative;
        border-radius: 100px;
        &::after{
            content: '';
            position: absolute;
            top:6px;
            left:0px;
            z-index: 0;
            width: 99%;
            height: 99%;
            border: 3px solid #000;
            border-radius: 100px;
        }
    }
    section.infoExtra{
        background-color: var(--color1);
        border:2px solid #000;
        border-radius: 15px;
        padding:20px;
        margin-bottom:50px;
        h2{
            text-align: center;
            margin-bottom:10px;
        }
        .descargables{
            background-color: #000;
            color:#fff;
            padding:20px;
            border-radius: 50px;

            display: flex;
            justify-content: center;
            align-items: center;
            gap:17px;
            margin-bottom:20px;
            a{
                color:#fff;
                &:hover{
                    color:var(--color1);
                }
            }
        }
        
        .firma{
            max-width: 800px;
            margin:auto;
            form{
                display: flex;
                flex-direction: column;
                align-items: center;
                .boxItem{
                    display: flex;
                    gap:20px;
                    align-items: center;
                    .btnClearFirma{
                        color:red;
                        font-size: 1.3em;
                        cursor: pointer;
                        transform: rotate(0deg);
                        padding:3px;
                        &:hover{
                            transform: rotate(90deg);
                            transition: .3s ease-out;
                        }
                    }
                }
            }
            .formItem{
                border:1px solid #000;
            }
            p{
                text-align: center;
            }
        }
    }
    section.evalSection{
        margin-bottom:50px;
        h2{
            background: #000;
            color:#fff;
            text-align: center;
            padding:15px 25px;
            border-radius: 50px;
            margin-bottom:15px;
            span{
                font-size: .75em;
                font-weight: 300;
            }
        }
        p.especial{
            background: #fff;
            text-align: center;
            padding:15px 25px;
            border-radius: 50px;
            margin-bottom:8px;
            a{
                color:#000;
                &:hover{
                    color:var(--color2);
                }
            }
        }
        .infExtra{
            margin-bottom:40px;
            background-color:#fff;
            border:2px solid #000;
            padding:20px;
            border-radius: 15px;
            h3{
                text-align: center;
            }
            p{
                text-align: center;
                margin-bottom: 20px;
            }
            ul{
                padding-left:25px;
                max-width: 800px;
                margin:auto;
                margin-bottom:30px;
            }
            table{
                max-width: 800px;
                margin:auto;
                border:1px solid #000;
                tr{
                    td, th{
                        vertical-align: top;
                        padding:10px;
                        &:nth-child(1){
                            width: 110px;
                            text-align: left;
                        } 
                        &:nth-child(2){
                            width: 40px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .boxEvalData{
            background: #fff;
            border:2px solid #000;
            border-radius: 16px;
            padding:20px;
            form{
                .criterio{
                    margin-bottom:30px;
                    h4{
                        margin-bottom:20px;
                    }
                    ul.rows{
                        margin-bottom:20px;
                        li.row{
                            list-style: none;
                            display: grid;
                            grid-template-columns: 1fr 150px;
                            padding:5px;
                            align-items: center;
                            &:nth-child(even){
                                background: #fafafa;
                            }
                            .boxItem{
                                margin:0px;
                                .formItem.inputSelect{
                                    select{
                                        border-radius: 50px;
                                    }
                                    i{
                                        color:var(--color2);
                                    }
                                }
                            }
                        }
                    }
                    .resultadoParcial{
                        margin-bottom:50px;
                        p{
                            text-align: right;
                            background-color: #f7f3ed;
                            padding:5px 20px;
                        }
                    }
                }
                .promedioTotal{
                    margin-bottom:50px;
                    p{
                        text-align: right;
                        background-color: #f7f3ed;
                        padding:10px 20px;
                        font-weight: 700;
                    }
                }
                .infoObserva{
                    margin-bottom:30px;
                }
                .observaciones{
                    margin-bottom:30px;
                    textarea{
                        width: 100%;
                        border:1px solid #000;
                        padding:5px;
                    }
                }
                .comentariosStaff{
                    ul{
                        margin-bottom: 50px;
                        li{
                            list-style: none;
                            padding:10px;

                            background-color: var(--color1);
                            border:1px solid #000;
                            border-radius: 17px;
                            margin-bottom: 10px;
                            .by{
                                font-size:.8em;
                                font-weight: 900;
                                span{
                                    color:var(--color2);
                                }
                            }
                            .comentarios{

                            }
                        }
                    }
                }
                .nota{
                    margin-bottom:30px;
                }
                .boxSubmit{
                    display: flex;
                    gap: 20px;
                    justify-content: space-between;
                    button.finalizar{
                        background-color: var(--color1) !important;
                        color:#000 !important;
                        border:1px solid #000 !important;
                        &:hover{
                            background-color: #000 !important;
                            color:#fff !important;
                        }
                    }
                }
            }
        }
    }
    section.prInfo{
        background: var(--color1);
        border:2px solid #000;
        border-radius: 16px;
        margin-bottom:40px;
        padding:20px;
        ul.headerEval{
            border-bottom:1px solid #000;
            li{
                margin-bottom:0px;
                &:last-child{
                    justify-self: flex-end;
                }
            }
        }
        div.grid-col2{
            grid-template-columns: 40% 60%;
        }
        div.resumen{
            padding:20px;
        }
        ul{
            padding:20px;
            li{
                list-style: none;
                margin-bottom:15px;
                span:nth-child(1){
                    font-size: .85rem;
                    display: block;
                    font-weight: 900;
                }
            }
        }
    }
    section.firma{
        .wrap{
            padding:20px;
            background-color: #fff;
            border:2px solid #000;
            border-radius: 17px;
        }
        h1{
            text-align: center;
        }
        div{
            max-width: 400px;
            border:1px solid #000;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}
article#notaFinal{
    background-color: rgba(0,0,0,0.8);
    color:#fff;
    padding:16px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    border-radius: 16px;

    position: fixed;
    bottom:10px;
    left: 10px;
    z-index: 500;
}
</style>